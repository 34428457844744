<template>
  <div>
    <KTCodePreview v-bind:title="'Báo cáo kích hoạt IMEI'">
      <template v-slot:toolbar>
        <div class="row">
          <div class="col-md-5">
            <b-dropdown size="sm" id="dropdown-1" right>
              <template slot="button-content">
                <i style="font-size: 1rem" class="fas fa-cog"></i>
                <span class="font-weight-bolder">Thao tác</span>
              </template>
              <b-dropdown-item @click="reportClick">
                <span>
                  <i style="font-size: 1rem" class="far fa-file-excel"></i>
                  &nbsp; Xuất Excel</span
                >
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <b-row class="pl-0 mb-4">
          <b-col md="3">
            <b-input
              size="sm"
              placeholder="Nhập mã IMEI"
              v-model="searchIMEI"
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </b-col>
          <b-col md="2">
            <BDropdownCustom
              v-model="selectedStatus"
              :options="optionsStatus"
              :placeholder="'chọn trạng thái'"
              :searchable="true"
              :matchKey="['label']"
              :textProp="'label'"
              :limit="5"
            />
          </b-col>
          <b-col md="2">
            <date-picker
              placeholder="Từ ngày"
              class="form-control-sm"
              :config="dpConfigs.start"
              v-model="dpForm.startDate"
            ></date-picker>
          </b-col>
          <b-col md="2">
            <date-picker
              placeholder="Đến ngày"
              class="form-control-sm"
              :config="dpConfigs.end"
              v-model="dpForm.endDate"
            ></date-picker>
          </b-col>
          <b-col md="3">
            <vue-autosuggest
              v-model="searchEmployee"
              :suggestions="filteredEmployeeOptions"
              @selected="onSelectedEmployee"
              :limit="10"
              @input="onSearchEmployeeChange"
              :input-props="{
                class: 'autosuggest__input',
                placeholder: 'Người tạo',
              }"
            >
              <div
                slot-scope="{ suggestion }"
                style="display: flex; align-items: center"
              >
                <div style="{ display: 'flex', color: 'navyblue'}">
                  {{ suggestion.item.suggestionName }}
                </div>
              </div>
            </vue-autosuggest>
          </b-col>
        </b-row>
        <b-row class="pl-0 mb-4">
          <b-col md="3">
            <b-input
              size="sm"
              placeholder="Nhập mã, tên sản phẩm"
              v-model="searchProduct"
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </b-col>
          <b-col md="2">
            <date-picker
              placeholder="Từ ngày tạo"
              class="form-control-sm"
              :config="dpConfigs.start"
              v-model="dpForm.createdFromDate"
            ></date-picker>
          </b-col>
          <b-col md="2">
            <date-picker
              placeholder="Đến ngày tạo"
              class="form-control-sm"
              :config="dpConfigs.end"
              v-model="dpForm.createdToDate"
            ></date-picker>
          </b-col>
        </b-row>
        <b-row class="pl-0">
          <b-col md="7" class="pl-0">
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter()"
              class="col-md-8 mb-4 ml-4"
              >Lọc</b-button
            >
          </b-col>
          <b-col class="pr-0">
            <div class="d-flex justify-content-end">
              <p
                class="mt-3 text-dark"
                style="font-weight: 500; font-size: 13px"
              >
                SL mỗi trang
              </p>
            </div>
          </b-col>
          <b-col md="3" class="pr-0 mt-3">
            <b-form-radio-group
              v-model="pageLimit"
              :options="ippOptions"
            ></b-form-radio-group>
          </b-col>
          <b-col class="pl-0">
            <div class="d-flex justify-content-end">
              <p
                class="mt-3 text-dark"
                style="font-weight: 500; font-size: 13px"
              >
                {{ fromNo }} - {{ toNo }} /
                {{ totalItem }}
              </p>
            </div>
          </b-col>
        </b-row>
        <div>
          <b-table
            :items="listItem"
            :fields="fields"
            bordered
            hover
            :busy="onLoading"
          >
            <template v-slot:table-busy>
              <vcl-table :speed="5" :animate="true" :columns="8"></vcl-table>
            </template>
            <template v-slot:cell(productName)="row">
              <span
                v-if="row.item.barCode"
                v-text="row.item.barCode"
                style="white-space: normal; font-size: 12px; font-weight: 500"
              ></span>
              <p
                v-if="row.item.productCode"
                style="font-size: 12px; color: darkorchid; margin-bottom: 0"
              >
                {{ row.item.productCode }}
              </p>
              <span
                v-text="row.item.productName"
                style="white-space: normal"
              ></span>
            </template>
            <template v-slot:cell(siteCode)="row">
              <span
                v-if="row.item.siteCode"
                v-text="row.item.siteCode"
                style="white-space: normal; font-size: 12px; font-weight: 500"
                class="text-primary"
              ></span>
              <br />
              <span
                v-text="row.item.storeName"
                style="white-space: normal"
              ></span>
            </template>
            <template v-slot:cell(customerPhone)="row">
              <span
                v-if="row.item.customerPhone"
                v-text="row.item.customerPhone"
                style="white-space: normal; font-size: 12px; font-weight: 500"
              ></span>
              <br />
              <span
                v-text="row.item.customerName"
                style="
                  white-space: normal;
                  color: lightslategray;
                  font-size: 13px;
                  font-weight: 500;
                "
              ></span>
            </template>
            <template v-slot:cell(createdAt)="row">
              <span
                v-if="row.item.createdAt"
                v-text="row.item.createdAt"
                style="white-space: normal; font-weight: 500"
              ></span>
              <br />
              <span
                v-text="row.item.employeeName"
                style="
                  white-space: normal;
                  color: lightslategray;
                  font-size: 13px;
                  font-weight: 500;
                "
              ></span>
            </template>
          </b-table>
        </div>
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng số :
              {{ totalItem }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              class="custom-pagination"
              v-if="numberOfPage >= 2"
              :link-gen="linkGen"
              :number-of-pages="numberOfPage"
              use-router
              @change="fetchData"
              align="right"
              first-class="page-item-first btn btn-icon mr-1 btn-sm my-1"
              prev-class="page-item-prev btn btn-icon mr-1 btn-sm my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 "
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-1 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
      </template>
    </KTCodePreview>
  </div>
</template>

<style scope>
.d-flex {
  display: -webkit-box !important;
}
</style>
<style>
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(1.35em + 1.1rem + 2px);
  width: auto;
}

.dropdown-header {
  color: #3699ff !important;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 999;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

.autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

.autosuggest {
  padding-left: 0px;
  padding-right: 25px;
}
.imeiCode:hover {
  text-decoration: underline;
}
.table-wrapper {
  overflow-x: scroll;
  overflow-y: visible;
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import { VclTable } from 'vue-content-loading';
import { currencyMask } from './../../../utils/common';
import axios from 'axios';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import { vi } from 'vuejs-datepicker/dist/locale';
import moment from 'moment';
import { BASE_URL } from './../../../utils/constants';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import fileDownload from '../../../utils/file-download';
import { VueAutosuggest } from 'vue-autosuggest';
import { xoa_dau } from '../../../utils/common';
import BDropdownCustom from '@/view/base/bootstrap/BDropdownCustom.vue';

export default {
  data() {
    return {
      fields: [
        {
          key: 'productName',
          label: 'Sản phẩm',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '12%',
          },
        },
        {
          key: 'imeiCode',
          label: 'Mã IMEI',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '5%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'siteCode',
          label: 'Cửa hàng',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '5%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'customerPhone',
          label: 'Khách hàng',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '8%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'activeDate',
          label: 'Ngày kích hoạt',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '5%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'statusName',
          label: 'Trạng thái',
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '10%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'desciption',
          label: 'Thông báo',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '30%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'createdAt',
          label: 'Ngày/ người thao tác',
          sortable: false,
          thStyle: {
            textAlign: 'center',
            color: 'rgb(24, 28, 50)',
            width: '15%',
          },
          tdClass: 'text-left',
        },
      ],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      page: 1,
      totalItem: 0,
      onLoading: false,
      numberOfPage: 0,
      searchIMEI: '',
      mask: currencyMask,
      dpForm: {
        startDate: moment().subtract(1, 'months').format('DD/MM/YYYY'),
        endDate: moment().format('DD/MM/YYYY'),
        createdFromDate: '',
        createdToDate: '',
      },
      dpConfigs: {
        start: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
        end: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      format: 'dd-MM-yyyy',
      language: vi,
      fromNo: 0,
      toNo: 0,
      pageLimit: 10,
      ippOptions: [
        { text: '10', value: '10' },
        { text: '30', value: '30' },
        { text: '50', value: '50' },
        { text: '100', value: '100' },
      ],
      listItem: [],
      optionsStatus: [
        {
          id: 0,
          label: 'Không xác định',
          shortName: 'KXD',
        },
        {
          id: 2,
          label: 'Sai ngày',
          shortName: 'SN',
        },
        {
          id: 4,
          label: 'Thành công',
          shortName: 'TC',
        },
        {
          id: 5,
          label: 'Kích hoạt trước',
          shortName: 'KHT',
        },
        {
          id: 6,
          label: 'Không thành công',
          shortName: 'KTC',
        },
        {
          id: 8,
          label: 'Nhập ngoài',
          shortName: 'NN',
        },
        {
          id: 15,
          label: 'Quá hạn',
          shortName: 'QH',
        },
        {
          id: 10,
          label: 'Hợp lệ',
          shortName: 'HL',
        },
        {
          id: 11,
          label: 'Sai mã',
          shortName: 'SM',
        },
        {
          id: 13,
          label: 'Sai sitecode',
          shortName: 'SSC',
        },
      ],
      selectedStatus: [],
      selectedEmployeeId: null,
      optionsEmployee: [
        {
          data: [],
        },
      ],
      filteredEmployeeOptions: [],
      searchEmployee: '',
      searchProduct: '',
    };
  },
  components: {
    KTCodePreview,
    VclTable,
    datePicker,
    VueAutosuggest,
    BDropdownCustom,
  },
  computed: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Kích hoạt IMEI', route: '/list-active-imei-report' },
      { title: 'Báo cáo kích hoạt IMEI' },
    ]);
    this.fetchData();
  },
  created() {
    this.fetchEmployee();
  },
  methods: {
    formatMoney: function (number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    fetchData: function () {
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      const searchFromDay = this.dpForm.startDate
        ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format(
            'yyyy-MM-DD 00:00:00',
          )
        : '';
      const searchToDay = this.dpForm.endDate
        ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format(
            'yyyy-MM-DD 23:59:59',
          )
        : '';
      const searchCreatedFromDay = this.dpForm.createdFromDate
        ? moment(this.dpForm.createdFromDate, 'DD/MM/YYYY').format(
            'yyyy-MM-DD 00:00:00',
          )
        : '';
      const searchCreatedToDay = this.dpForm.createdToDate
        ? moment(this.dpForm.createdToDate, 'DD/MM/YYYY').format(
            'yyyy-MM-DD 23:59:59',
          )
        : '';
      if (!this.searchEmployee || this.searchEmployee.trim() === '') {
        this.selectedEmployeeId = null;
      }
      let listIdStatus = [];
      if (this.selectedStatus.length) {
        listIdStatus = this.selectedStatus.map(({ id }) => id);
      }
      const param = {
        page: this.page,
        limit: this.pageLimit,
        searchImei: this.searchIMEI,
        fromDate: searchFromDay,
        toDate: searchToDay,
        status: listIdStatus,
        employeeId: this.selectedEmployeeId,
        searchProduct: this.searchProduct,
        createdFromDate: searchCreatedFromDay,
        createdToDate: searchCreatedToDay,
      };
      const paramQuery = {
        params: param,
      };
      this.onLoading = true;
      this.listItem = [];
      ApiService.query('imei-active-reports', paramQuery).then(({ data }) => {
        this.listItem = data.data.data;
        this.numberOfPage = data.data.totalPage;
        this.totalItem = data.data.totalRow;
        this.onLoading = false;
        this.fromNo = (this.page - 1) * this.pageLimit + 1;
        this.toNo = this.pageLimit * this.page;
        if (this.toNo > this.totalItem) {
          this.toNo = this.totalItem;
        }
      });
    },
    reportClick: async function () {
      const searchFromDay = this.dpForm.startDate
        ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format(
            'yyyy-MM-DD 00:00:00',
          )
        : '';
      const searchToDay = this.dpForm.endDate
        ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format(
            'yyyy-MM-DD 23:59:59',
          )
        : '';
      const searchCreatedFromDay = this.dpForm.createdFromDate
        ? moment(this.dpForm.createdFromDate, 'DD/MM/YYYY').format(
            'yyyy-MM-DD 00:00:00',
          )
        : '';
      const searchCreatedToDay = this.dpForm.createdToDate
        ? moment(this.dpForm.createdToDate, 'DD/MM/YYYY').format(
            'yyyy-MM-DD 23:59:59',
          )
        : '';
      if (!this.searchEmployee || this.searchEmployee.trim() === '') {
        this.selectedEmployeeId = null;
      }
      let listIdStatus = [];
      if (this.selectedStatus.length) {
        listIdStatus = this.selectedStatus.map(({ id }) => id);
      }
      const params = {
        searchImei: this.searchIMEI,
        fromDate: searchFromDay,
        toDate: searchToDay,
        status: listIdStatus,
        employeeId: this.selectedEmployeeId,
        searchProduct: this.searchProduct,
        createdFromDate: searchCreatedFromDay,
        createdToDate: searchCreatedToDay,
      };
      const url = `${BASE_URL}imei-active-reports/excel`;
      await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
        params: params,
      }).then((response) => {
        const fileName = fileDownload.getFileName(response);
        fileDownload.createFile(response.data, fileName);
      });
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-active-imei-report',
      });
      this.fetchData();
    },
    onSelectedEmployee(option) {
      this.selectedEmployeeId = option.item.id;
      this.searchEmployee = option.item.name;
    },
    onSearchEmployeeChange(text) {
      if (!text) {
        text = '';
      }
      this.searchEmployee = text;
      const filteredData = this.optionsEmployee[0].data
        .filter((item) => {
          return (
            item.nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredEmployeeOptions = [
        {
          data: filteredData,
        },
      ];
    },
    fetchEmployee: async function () {
      this.optionsEmployee[0].data = [];
      ApiService.get('/employees/get-list-filter').then((response) => {
        const employees = response.data.data;
        this.optionsEmployee[0].data = employees.map((element) => {
          let suggestionName = element.name;
          if (element.code) {
            suggestionName += ' ( ' + element.code + ')';
          }
          const nameNoneSign = xoa_dau(element.name);
          return {
            id: element.id,
            name: element.name,
            code: element.code,
            suggestionName: suggestionName,
            nameNoneSign: nameNoneSign,
          };
        });
        this.filteredEmployeeOptions = [{ data: this.optionsEmployee[0].data }];
      });
    },
  },
};
</script>
